import { debounce } from 'lodash'

import { validateUser } from 'data/api'
import { isEmailValid, isFullNameValid, isLoginValid } from '_libs/validators'
import { ValidateUserArgs } from 'types/api'

const DEBOUNCE_DELAY = 500

const validateAsync = async (field: keyof ValidateUserArgs['user'], value: string) => {
  const response = await validateUser({ user: { [field]: value } })

  if ('errors' in response) {
    return response.errors.find(error => error.field === field)?.value ?? true
  }

  return true
}

const validateLoginAsync = debounce(
  (value: string) => validateAsync('login', value),
  DEBOUNCE_DELAY,
)
const validateRealNameAsync = debounce(
  (value: string) => validateAsync('real_name', value),
  DEBOUNCE_DELAY,
)
const validatePasswordAsync = debounce(
  (value: string) => validateAsync('password', value),
  DEBOUNCE_DELAY,
)

export const validateRealName = (value: string) =>
  isFullNameValid(value) && validateRealNameAsync(value)

export const validateEmail = (value: string) => isEmailValid(value)

export const validateLogin = (value: string) => isLoginValid(value) && validateLoginAsync(value)

export const validatePassword = (value: string) => validatePasswordAsync(value)
