'use client'

import { useCallback, useMemo, useState } from 'react'

import useCookie from '_libs/common/cookie-manager/hooks/useCookie'
import { cookiesDataByName } from '_libs/common/cookie-manager/cookies-data'

import AuthenticationContext from './AuthenticationContext'
import { AuthExternalRegisterView, AuthView } from '../constants'
import { ExternalRegisterData, TwoFactorLoginData } from '../types'

export const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
  const cookies = useCookie()

  const defaultAuthView = useMemo(() => {
    return cookies.get(cookiesDataByName.last_user_id)
      ? AuthView.SelectTypeLogin
      : AuthView.SelectTypeRegister
  }, [cookies])

  const [authView, setAuthView] = useState<AuthView | AuthExternalRegisterView>(defaultAuthView)
  const [externalRegisterData, setExternalRegisterData] = useState<ExternalRegisterData>()
  const [twoFactorLoginData, setTwoFactorLoginData] = useState<TwoFactorLoginData>()

  const handleViewExternalRegister = useCallback(
    ({ view, data }: { view: AuthExternalRegisterView; data: ExternalRegisterData }) => {
      setAuthView(view)
      setExternalRegisterData(data)
    },
    [],
  )

  const handleViewTwoFactorLogin = useCallback((data: TwoFactorLoginData) => {
    setAuthView(AuthView.TwoFactorLogin)
    setTwoFactorLoginData(data)
  }, [])

  const resetAuthView = useCallback(() => {
    setAuthView(defaultAuthView)
  }, [defaultAuthView])

  const value = useMemo(
    () => ({
      authView,
      externalRegisterData,
      twoFactorLoginData,
      handleViewExternalRegister,
      handleViewTwoFactorLogin,
      resetAuthView,
      setAuthView,
    }),
    [
      authView,
      externalRegisterData,
      twoFactorLoginData,
      handleViewExternalRegister,
      handleViewTwoFactorLogin,
      resetAuthView,
    ],
  )

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}
