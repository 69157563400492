import useRefUrl from 'hooks/useRefUrl'

import { BUSINESS_ACCOUNT_REGISTER_URL } from 'constants/routes'
import useAuthModal from '_libs/common/auth-modal/useAuthModal'

const useSuccessUrl = (defaultUrl?: string) => {
  const { isBusinessAuth } = useAuthModal()
  const refUrl = useRefUrl(defaultUrl)

  if (isBusinessAuth) return BUSINESS_ACCOUNT_REGISTER_URL

  return refUrl
}

export default useSuccessUrl
