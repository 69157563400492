import { ReactNode } from 'react'
import { isFragment } from 'react-is'
import { compact, isArray, isEmpty } from 'lodash'

export const isEmptyFragment = (instance: ReactNode) => {
  if (!isFragment(instance)) return false

  const children = instance.props?.children

  if (isArray(children)) return isEmpty(compact(children))

  return !children
}
