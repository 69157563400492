import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import useTracking from 'hooks/useTracking'
import { abTestExposeEvent } from '_libs/common/event-tracker/events'
import { getLocalStorageItem, setLocalStorageItem } from '_libs/utils/localStorage'
import { getExposee, getAnonId } from 'state/session/selectors'
import { getSessionStorageItem, setSessionStorageItem } from '_libs/utils/sessionStorage'
import { AbTestsContext } from 'contexts/AbTestsProvider'
import { getAbTestByName } from 'state/ab-tests/selectors'

const STORAGE_KEY_PREFIX = 'use-track-ab-test-expose-event-'

type Props = {
  abTestName: string
  shouldTrackExpose?: boolean
  shouldTrackOnce?: boolean
  shouldTrackOncePerSessionDay?: boolean
}

const useAbTest = ({
  abTestName,
  shouldTrackExpose,
  shouldTrackOnce,
  shouldTrackOncePerSessionDay,
}: Props) => {
  const abTests = useContext(AbTestsContext)
  const abTestFromContext = abTests ? abTests[abTestName] : undefined
  const abTestFromRedux = useSelector(getAbTestByName(abTestName))
  const isAbTestRefactorEnabled = useFeatureSwitch('next_ab_test_slice_refactor')

  const abTest = isAbTestRefactorEnabled ? abTestFromContext : abTestFromRedux
  const exposee = useSelector(getExposee)
  const annonId = useSelector(getAnonId)

  const { track } = useTracking()

  useEffect(() => {
    if (!shouldTrackExpose) return
    if (!abTest) return

    if (shouldTrackOnce) {
      const localStorageKey = `${STORAGE_KEY_PREFIX}${abTest.name}`

      if (getLocalStorageItem(localStorageKey) === annonId) return

      setLocalStorageItem(localStorageKey, annonId)
    }

    if (shouldTrackOncePerSessionDay) {
      const key = `${abTest.name}_test_exposed_day`
      const dateToday = new Date().toLocaleDateString()
      const lastExposureDate = getSessionStorageItem(key)

      if (lastExposureDate === dateToday) return

      setSessionStorageItem(key, dateToday)
    }

    track(abTestExposeEvent({ ...exposee, ...abTest }))
  }, [
    abTest,
    annonId,
    exposee,
    shouldTrackExpose,
    shouldTrackOnce,
    track,
    shouldTrackOncePerSessionDay,
  ])

  return abTest
}

export default useAbTest
