'use client'

import { Component } from 'react'
import { Button, Cell, Checkbox, Dialog, Spacer, Text, Icon } from '@vinted/web-ui'
import { Settings24 } from '@vinted/monochrome-icons'

import List from 'components/List'
import { ROOT_URL } from 'constants/routes'
import { generateRandomUser } from 'data/api/authentication/requests'
import { navigateToPage } from '_libs/utils/window'

type State = {
  error: boolean
  isLoading: boolean
  isSettingsModalOpen: boolean
  isGod: boolean
}

class RandomUserSignup extends Component<Record<never, never>, State> {
  state: Readonly<State> = {
    error: false,
    isLoading: false,
    isSettingsModalOpen: false,
    isGod: true,
  }

  setError = (error = false) => {
    this.setState({ error })
  }

  setIsLoading = (isLoading = true) => {
    this.setState({ isLoading })
  }

  toggleModal = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (this.state.isLoading) {
      event.preventDefault()

      return
    }

    this.setState(prevState => ({ isSettingsModalOpen: !prevState.isSettingsModalOpen }))
  }

  generateUser = async (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    const { isLoading, isGod } = this.state

    if (isLoading) {
      event.preventDefault()

      return
    }

    this.setError()
    this.setIsLoading()

    try {
      await generateRandomUser({
        isGod,
      })

      navigateToPage(ROOT_URL)
    } catch (exception) {
      this.setError(true)
    } finally {
      this.setIsLoading(false)
    }
  }

  toggleIsGod = () => {
    this.setState(prevState => ({ isGod: !prevState.isGod }))
  }

  renderModal = () => {
    const { isSettingsModalOpen, isLoading, isGod } = this.state

    return (
      <Dialog show={isSettingsModalOpen}>
        <List>
          <Cell styling={Cell.Styling.Wide}>
            <Text
              as="h2"
              type={Text.Type.Title}
              alignment={Text.Alignment.Center}
              width={Text.Width.Parent}
              text="Generate user settings"
            />
          </Cell>
          <Cell
            styling={Cell.Styling.Wide}
            title="Assign god role?"
            suffix={
              <Checkbox
                name="is_god"
                checked={isGod}
                onClick={this.toggleIsGod}
                onChange={() => undefined}
              />
            }
          />
          <Cell styling={Cell.Styling.Wide}>
            <Button
              text="Save"
              styling={Button.Styling.Filled}
              isLoading={isLoading}
              onClick={this.toggleModal}
            />
          </Cell>
        </List>
      </Dialog>
    )
  }

  render() {
    const { isLoading, error } = this.state

    return (
      <>
        {error && (
          <>
            <Text
              as="h3"
              theme="warning"
              type={Text.Type.Subtitle}
              alignment={Text.Alignment.Center}
              width={Text.Width.Parent}
              text="Server error"
            />
            <Spacer />
          </>
        )}
        <div className="u-flexbox">
          <Button text="Generate random user" isLoading={isLoading} onClick={this.generateUser} />
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Button
            icon={<Icon name={Settings24} color={Icon.Color.Primary} />}
            inline
            onClick={this.toggleModal}
          />
        </div>
        {this.renderModal()}
      </>
    )
  }
}

export default RandomUserSignup
