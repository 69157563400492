'use client'

import { useEffect, useState } from 'react'
import { Button } from '@vinted/web-ui'

import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'

import { GOOGLE_OAUTH_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getGoogleIdToken } from 'data/api'
import { decodeJwt } from '_libs/utils/authentication'
import { getGoogleRedirectUrl } from '_libs/utils/google'
import { normalizedQueryParam, urlWithParams } from '_libs/utils/url'

import useSuccessUrl from '../hooks/useSuccessUrl'
import useAuthTracking from '../hooks/useAuthTracking'

const GoogleIcon = () => (
  <div className="u-flexbox u-padding-small">
    <svg fill="none" viewBox="0 0 24 24" width="20" height="20">
      <path
        fill="#4285F4"
        d="M23.754 12.23c0-.852-.06-1.625-.204-2.417H12.24v4.646h6.46c-.277 1.5-1.13 2.774-2.402 3.626v3.014h3.878c2.27-2.09 3.578-5.163 3.578-8.825v-.043Z"
      />
      <path
        fill="#34A853"
        d="M12.24 24.004c3.242 0 5.955-1.08 7.948-2.905l-3.878-3.014c-1.08.72-2.449 1.14-4.07 1.14-3.121 0-5.775-2.113-6.723-4.946h-4.01v3.11c1.98 3.926 6.039 6.615 10.733 6.615Z"
      />
      <path
        fill="#FBBC04"
        d="M5.517 14.279a7.21 7.21 0 0 1-.372-2.281c0-.792.132-1.56.372-2.281v-3.11h-4.01a11.964 11.964 0 0 0-1.273 5.39c0 1.934.468 3.77 1.273 5.392l4.01-3.11Z"
      />
      <path
        fill="#E94235"
        d="M12.24 4.77c1.765 0 3.35.6 4.586 1.801l3.446-3.445C18.195 1.193 15.47.004 12.24.004 7.546-.008 3.488 2.68 1.507 6.607l4.01 3.11c.948-2.834 3.59-4.947 6.723-4.947Z"
      />
    </svg>
  </div>
)

export type GoogleUserFields = {
  email: string
  name?: string
}

export type OnGoogleLoginSuccess = {
  token: string
  fields: GoogleUserFields
  redirectUrl?: string
}

type Props = {
  onSuccess: (props: OnGoogleLoginSuccess) => void
  onFailure: () => void
}

const GoogleOauthButton = ({ onSuccess, onFailure }: Props) => {
  const { relativeUrl, searchParams, host } = useLocation()
  const successUrl = useSuccessUrl(relativeUrl)
  const translate = useTranslate('auth.select_type')
  const { trackClickEvent } = useAuthTracking()

  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const isHostNextjs = host === 'localhost:3501' || undefined

  const oauthUrl = urlWithParams(GOOGLE_OAUTH_URL, {
    redirect_uri: urlWithParams(successUrl, searchParams),
    next_local_redirect: isHostNextjs,
  })
  const code = normalizedQueryParam(searchParams.code)
  const state = normalizedQueryParam(searchParams.state)
  const redirectUrl = getGoogleRedirectUrl(state)

  useEffect(() => {
    if (!code || !state || isLoggingIn) return

    const fetchGoogleToken = async () => {
      const response = await getGoogleIdToken({
        code,
        state,
        isLocalRedirectEnabled: isHostNextjs,
      })

      if ('errors' in response) {
        onFailure()

        return
      }

      const { id_token: token } = response

      const { email, name } = decodeJwt(token)

      onSuccess({ token, fields: { name, email }, redirectUrl })
    }

    setIsLoggingIn(true)
    fetchGoogleToken()
  }, [code, isLoggingIn, redirectUrl, state, onSuccess, onFailure, isHostNextjs])

  const handleClick = () => {
    setIsLoggingIn(true)
    trackClickEvent({ target: ClickableElement.LoginWithGoogle })
  }

  return (
    <Button
      theme="amplified"
      onClick={handleClick}
      url={oauthUrl}
      isLoading={isLoggingIn}
      // TODO: Replace with GoogleLogo24 from @vinted/multichrome-icons when it's updated
      icon={<GoogleIcon />}
    >
      {translate('actions.google')}
    </Button>
  )
}

export default GoogleOauthButton
