'use client'

import { Component, Fragment, ReactComponentElement, ReactNode } from 'react'
import { Spacer, Divider } from '@vinted/web-ui'
import { compact } from 'lodash'

import { BreakpointProps, withBreakpoint } from 'components/Breakpoint'
import { BreakpointMap } from 'types/components'

import { isEmptyFragment } from './utils'

type Props = BreakpointProps & {
  children: Array<ReactNode>
  separator: ReactComponentElement<typeof Divider | typeof Spacer>
  separatorAtBreakpoint?: Partial<
    Record<keyof BreakpointMap, ReactComponentElement<typeof Divider | typeof Spacer>>
  >
}

class SeparatedList extends Component<Props> {
  static defaultProps = {
    separator: <Spacer orientation={Spacer.Orientation.Vertical} />,
  }

  getBreakpointSeparator() {
    const { separator, separatorAtBreakpoint, breakpoints } = this.props

    if (!separatorAtBreakpoint) return separator

    const activeBreakpoint = breakpoints.active.find(
      breakpoint => separatorAtBreakpoint[breakpoint],
    )

    if (!activeBreakpoint) return separator

    return separatorAtBreakpoint[activeBreakpoint]
  }

  render() {
    const items = compact(this.props.children.flat()).filter(child => !isEmptyFragment(child))

    if (!items?.length) {
      return null
    }

    const breakpointSeparator = this.getBreakpointSeparator()

    return items.map((item, index) => (
      <Fragment key={index}>
        {item}
        {items.length - 1 !== index && breakpointSeparator}
      </Fragment>
    ))
  }
}

export default withBreakpoint(SeparatedList)
