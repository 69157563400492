'use client'

import { MouseEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { InputText, Button, Cell, Spacer, Text } from '@vinted/web-ui'

import useLocation from 'hooks/useLocation'
import useTranslate from 'hooks/useTranslate'
import useGoogleTagManagerTrack from 'hooks/useGoogleTagManagerTrack'

import { AuthenticateGrantType } from 'constants/auth'
import { GoogleTagManagerEvent } from 'constants/google'
import { FORGOT_PASSWORD_URL, SIGNUP_HELP_ENTRIES_URL } from 'constants/routes'
import { getCurrentUser } from 'data/api'
import { authenticateUser } from 'data/api/authentication/requests'
import { ResponseCode } from 'data/api/response-codes'
import { transformAuthenticateUserError } from 'data/transformers/authentication'
import { LoginUserArgs } from 'types/api'

import { getFingerprint } from '_libs/utils/fingerprint'
import { navigateToPage } from '_libs/utils/window'
import useDataDomeCaptcha from 'hooks/useDataDomeCaptcha'

import { AuthView } from 'components/Auth/constants'

import PasswordField from '../PasswordField'
import useSuccessUrl from '../hooks/useSuccessUrl'
import { isBlockedByDataDome, isNetworkError } from '../utils'
import useAuthenticationContext from '../hooks/useAuthenticationContext'

type FormData = {
  username: string
  password: string
}

const EmailLogin = () => {
  const { login } = useLocation().searchParams

  const username = typeof login === 'string' ? login : undefined
  const [isLoading, setIsLoading] = useState(false)

  const translate = useTranslate('auth.email.login')
  const selectTypeTranslate = useTranslate('auth.select_type')
  const { register, handleSubmit } = useForm<FormData>({ defaultValues: { username } })
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const { setAuthView, handleViewTwoFactorLogin } = useAuthenticationContext()

  const [error, setError] = useState('')
  const [formData, setFormData] = useState<FormData>()
  const successUrl = useSuccessUrl()

  function handleForgotPasswordClick(event: MouseEvent) {
    event.preventDefault()
    setAuthView(AuthView.ResetPassword)
  }

  async function performLogin(data: FormData) {
    setIsLoading(true)

    const fingerprint = await getFingerprint()

    const authenticateUserArgs: LoginUserArgs = {
      grantType: AuthenticateGrantType.Password,
      fingerprint,
      ...data,
    }

    const response = await authenticateUser(authenticateUserArgs)

    const is2FARequired =
      response.code === ResponseCode.Required2FA ||
      response.code === ResponseCode.VerifierSecondFactorRequired

    if (isNetworkError(response)) {
      setError(selectTypeTranslate('errors.offline'))
      setIsLoading(false)

      return
    }

    if (isBlockedByDataDome(response)) {
      setError(selectTypeTranslate('errors.datadome'))

      return
    }

    if (is2FARequired && 'payload' in response && response.payload) {
      handleViewTwoFactorLogin({
        ...transformAuthenticateUserError(response.payload),
        refUrl: successUrl,
      })

      return
    }

    if ('errors' in response) {
      // TODO: handle ResponseCode.SessionFromTokenError
      setError(response.message)
      setIsLoading(false)

      return
    }

    const userResp = await getCurrentUser()
    const user = 'errors' in userResp ? null : userResp.user

    googleTagManagerTrack(GoogleTagManagerEvent.Login, {
      auth_type: 'email',
      user_email: user?.email,
    })

    navigateToPage(successUrl)
  }

  function handleFormSubmit(data: FormData) {
    setIsLoading(true)
    setError('')
    setFormData(data)

    performLogin(data)
  }

  useDataDomeCaptcha(() => {
    if (!formData) return

    handleFormSubmit(formData)
  })

  function renderDescription() {
    return (
      <div className="u-ui-margin-horizontal-large">
        <Text
          as="h1"
          id="auth_modal_title"
          text={translate('title')}
          type={Text.Type.Heading}
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
        />
        <Spacer size={Spacer.Size.Large} />
        {!!error && (
          <Text
            as="span"
            text={error}
            theme="warning"
            width={Text.Width.Parent}
            alignment={Text.Alignment.Center}
            html
          />
        )}
      </div>
    )
  }

  function renderFields() {
    return (
      <>
        <InputText
          {...register('username', {
            required: true,
          })}
          placeholder={translate('fields.login')}
          disabled={!!username}
        />
        {!!username && <input type="hidden" {...register('username')} value={username} />}
        <PasswordField
          {...register('password', {
            required: true,
          })}
          placeholder={translate('fields.password')}
        />
      </>
    )
  }

  function renderActions() {
    return (
      <Cell>
        <Button
          text={translate('actions.submit')}
          type={Button.Type.Submit}
          styling={Button.Styling.Filled}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            if (isLoading) {
              event.preventDefault()
            }
          }}
        />
        <Spacer size={Spacer.Size.XLarge} />
        <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
          <a
            data-testid="forgot-password-link"
            href={FORGOT_PASSWORD_URL}
            onClick={handleForgotPasswordClick}
          >
            {translate('actions.forgot_password')}
          </a>
        </Text>
        <Spacer size={Spacer.Size.Medium} />
        <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
          <a href={SIGNUP_HELP_ENTRIES_URL}>{translate('actions.help')}</a>
        </Text>
        <Spacer />
      </Cell>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      {renderDescription()}
      {renderFields()}
      <Spacer />
      {renderActions()}
    </form>
  )
}

export default EmailLogin
