import { FormName, PageType } from 'constants/google'
import { PageId } from 'types/page-id'

export function isProductPage(pageId: PageId | null) {
  return pageId === 'item'
}

/**
 * NOTE:
 * This method returns the current catalog url (top two level) in english
 * on catalog page
 * */
export function getCurrentCatalogUrl(url: string | undefined) {
  return url?.split('/').splice(1, 2).join('/') || ''
}

const pageTypeFormNameMap: Partial<Record<PageType, FormName>> = {
  [PageType.Checkout]: FormName.Checkout,
  [PageType.AddListing]: FormName.AddListing,
}

export function getFormName(pageType: PageType) {
  return pageTypeFormNameMap[pageType] || ''
}

export function getPageType(
  relativeUrl: string,
  searchString: string | null,
  pageId: PageId | null,
) {
  if (searchString) return PageType.SearchResult
  if (isProductPage(pageId)) return PageType.ProductDisplay

  if (relativeUrl.endsWith('/checkout')) return PageType.Checkout
  if (relativeUrl.endsWith('/member/items/favourite_list')) return PageType.Favourites
  if (relativeUrl.endsWith('/items/new')) return PageType.AddListing

  return PageType.Others
}
