import { Component, JSXElementConstructor } from 'react'

import { getDisplayName } from '@marketplace-web/shared/react-helpers'
import { Breakpoints } from 'types/components'

import { Consumer } from './BreakpointContext'

export type BreakpointProps = {
  breakpoints: Breakpoints
}

export default function withBreakpoint<P extends BreakpointProps, C>(
  WrappedComponent: JSXElementConstructor<P> & C,
) {
  type Props = JSX.LibraryManagedAttributes<C, Omit<P, 'breakpoints'>>

  return class extends Component<Props> {
    static displayName = getDisplayName(WrappedComponent)

    static WrappedComponent = WrappedComponent

    render() {
      return (
        <Consumer>
          {({ breakpoints }) => <WrappedComponent {...(this.props as any)} {...{ breakpoints }} />}
        </Consumer>
      )
    }
  }
}
